document.addEventListener('DOMContentLoaded', function() {
    // Configurar rolagem suave para links de âncora
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            const targetId = this.getAttribute('href');
            const targetElement = document.querySelector(targetId);
            
            if (targetElement) {
                // Fechar o menu mobile se estiver aberto
                const navbarCollapse = document.querySelector('.navbar-collapse');
                if (navbarCollapse.classList.contains('show')) {
                    const bsCollapse = new bootstrap.Collapse(navbarCollapse);
                    bsCollapse.hide();
                }

                // Rolar suavemente até o elemento
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        });
    });

    const form = document.getElementById('contactForm');
    const submitButton = form.querySelector('button[type="submit"]');
    const originalButtonText = submitButton.innerHTML;
    const RECAPTCHA_SITE_KEY = '6LfLJ_cqAAAAADKxdMTYRbZYEZIBG437WEcdH9kK';
    const MIN_TIME_BETWEEN_SUBMITS = 60000; // 1 minuto em milissegundos
    let lastSubmitTime = 0;

    // Validação de campos
    const validateField = (field, validation) => {
        const value = field.value.trim();
        const isValid = validation(value);
        field.classList.toggle('is-invalid', !isValid);
        field.classList.toggle('is-valid', isValid);
        return isValid;
    };

    // Validações específicas
    const validations = {
        name: (value) => value.length >= 3,
        email: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        phone: (value) => /^\(\d{2}\) \d{4,5}-\d{4}$/.test(value),
        message: (value) => value.length >= 10
    };

    // Formatar telefone
    const formatPhone = (input) => {
        let value = input.value.replace(/\D/g, '');
        if (value.length > 11) value = value.slice(0, 11);
        
        if (value.length > 2) {
            value = `(${value.slice(0,2)}) ${value.slice(2)}`;
        }
        if (value.length > 10) {
            value = `${value.slice(0,10)}-${value.slice(10)}`;
        }
        
        input.value = value;
    };

    // Adicionar formatação de telefone
    const phoneInput = form.querySelector('input[name="phone"]');
    phoneInput.addEventListener('input', (e) => formatPhone(e.target));

    // Validar campos em tempo real
    Object.keys(validations).forEach(fieldName => {
        const field = form.querySelector(`[name="${fieldName}"]`);
        if (field) {
            field.addEventListener('input', () => {
                validateField(field, validations[fieldName]);
            });
        }
    });

    // Verificar tempo entre envios
    const checkTimeBetweenSubmits = () => {
        const now = Date.now();
        const timeSinceLastSubmit = now - lastSubmitTime;
        
        if (timeSinceLastSubmit < MIN_TIME_BETWEEN_SUBMITS) {
            const remainingTime = Math.ceil((MIN_TIME_BETWEEN_SUBMITS - timeSinceLastSubmit) / 1000);
            throw new Error(`Por favor, aguarde ${remainingTime} segundos antes de enviar outra mensagem.`);
        }
        
        return true;
    };

    // Verificar campo honeypot
    const checkHoneypot = () => {
        const honeypot = form.querySelector('input[name="_gotcha"]');
        if (honeypot && honeypot.value.trim() !== '') {
            throw new Error('Detectado possível envio automatizado.');
        }
        return true;
    };

    // Obter token do reCAPTCHA
    const getRecaptchaToken = async () => {
        try {
            const token = await grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
            return token;
        } catch (error) {
            console.error('Erro ao obter token do reCAPTCHA:', error);
            throw new Error('Erro ao validar segurança do formulário.');
        }
    };

    // Envio do formulário
    form.addEventListener('submit', async (e) => {
        e.preventDefault();

        try {
            // Validar todos os campos
            let isValid = true;
            Object.keys(validations).forEach(fieldName => {
                const field = form.querySelector(`[name="${fieldName}"]`);
                if (field) {
                    isValid = validateField(field, validations[fieldName]) && isValid;
                }
            });

            if (!isValid) {
                return;
            }

            // Verificações de segurança
            checkTimeBetweenSubmits();
            checkHoneypot();

            // Desabilitar botão e mostrar loading
            submitButton.disabled = true;
            submitButton.innerHTML = '<i class="fas fa-spinner fa-spin me-2"></i>Enviando...';

            // Obter token do reCAPTCHA
            const recaptchaToken = await getRecaptchaToken();

            // Preparar dados do formulário
            const formData = new FormData(form);
            formData.append('g-recaptcha-response', recaptchaToken);

            // Enviar formulário via API do Formspree
            const response = await fetch(form.action, {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (response.ok) {
                // Atualizar tempo do último envio
                lastSubmitTime = Date.now();

                // Mostrar mensagem de sucesso
                const successMessage = document.createElement('div');
                successMessage.className = 'alert alert-success mt-3';
                successMessage.innerHTML = `
                    <i class="fas fa-check-circle me-2"></i>
                    Mensagem enviada com sucesso! Entraremos em contato em breve.
                `;
                form.appendChild(successMessage);

                // Limpar formulário
                form.reset();
                
                // Remover mensagem após 5 segundos
                setTimeout(() => {
                    successMessage.remove();
                }, 5000);
            } else {
                throw new Error('Erro ao enviar mensagem');
            }
        } catch (error) {
            // Mostrar mensagem de erro
            const errorMessage = document.createElement('div');
            errorMessage.className = 'alert alert-danger mt-3';
            errorMessage.innerHTML = `
                <i class="fas fa-exclamation-circle me-2"></i>
                ${error.message || 'Desculpe, ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente.'}
            `;
            form.appendChild(errorMessage);
            
            // Remover mensagem após 5 segundos
            setTimeout(() => {
                errorMessage.remove();
            }, 5000);
        } finally {
            // Restaurar botão
            submitButton.disabled = false;
            submitButton.innerHTML = originalButtonText;
        }
    });
}); 